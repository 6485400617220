<template>
    <div>
       <el-form :inline="true" :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-form-inline" size="small">
           <el-row>
              <el-col :span="6">
                <el-form-item label="企业名称" prop="enterpriseName">
                  <el-input v-model="ruleForm.enterpriseName" placeholder="请输入企业名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="手机号" prop="phone">
                  <el-input v-model="ruleForm.phone" placeholder="请输入手机号"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="邮箱" prop="email">
                  <el-input v-model="ruleForm.email" placeholder="请输入邮箱"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="留言详情" prop="detail">
                  <el-input v-model="ruleForm.detail" type="textarea" placeholder="请输入留言详情"></el-input>
                </el-form-item>
              </el-col>
          </el-row>
          <el-row style="text-align: center">
              <el-form-item>
                <el-button type="primary" @click="onSubmit('ruleForm')" size="mini">提交</el-button>
                <el-button type="primary" @click="resetForm('ruleForm')" size="mini">重置</el-button>
              </el-form-item>
          </el-row>
        </el-form>
    </div>
</template>
<script>
import { requestConsultateMessage } from "../../request/moudle/znzz";

export default {
  name: "advice",
  components:{
  },
  data(){
   var checkPhone = (rule, value, callback) => {
      const reg = /^1([38][0-9]|4[014-9]|[59][0-35-9]|6[2567]|7[0-8])\d{8}$/
      setTimeout(() => {
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('请正确输入手机号码!'))
        }
      }, 100)
    }
    var checkEmail = (rule, value, callback) => {//邮箱校验
        const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
        if (!value) {
          return callback(new Error('邮箱不能为空!'))
        }
        setTimeout(() => {
          if (mailReg.test(value)) {
            callback()
          } else {
            callback(new Error('请输入正确的邮箱格式!'))
          }
        }, 100)
    }
    return  { 
      ruleForm: {
        enterpriseName: '',
        detail: '',
        phone: '',
        email: '',
      },
      rules: {
          enterpriseName: [
            { required: true, message: '请输入企业名称', trigger: 'blur' },
          ],
           phone: [
            { required: true, message: '请输入手机号', trigger: 'blur', validator: checkPhone},
          ],
          detail: [
            { required: true, message: '请输入留言详情', trigger: 'blur' },
          ],
          email: [
            { required: true, message: '请输入邮箱', trigger: 'blur', validator: checkEmail},
          ],
        }
    }     
  },
   methods: {
      onSubmit(formName){
        this.$api.znzz.requestConsultateMessage(this.ruleForm).then((res)=>{
            this.$message.success('提交成功');
            this.$refs[formName].resetFields();
        })
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
   }
  }
</script>
<style lang="scss" scoped>
    /deep/ .el-input__inner{
        padding: 0 8px;
    }
    /deep/ .el-form-item {
        margin: 0 12px 12px 0!important
    }
    /deep/ .el-form-item__label {
        font-size: 14px;
    }
</style>